<template>
    <GLText
        :visible="false"
        v-for="(font, i) in fonts"
        :key="i"
        :options="{ font }"
        @sync="nSync++"
    />
</template>

<script setup>
    import { shallowRef, watch } from 'vue';

    import GLText from './GLText.vue';

    const emit = defineEmits(['sync']);
    const nSync = shallowRef(0);

    const fonts = shallowRef([
        '/fonts/manuka/Manuka-Black.otf',
        '/fonts/abc-diatype/ABCDiatypeMono-Medium-Trial.woff',
        '/fonts/inter/Inter_18pt-Black.ttf',
    ]);

    watch(nSync, () => {
        if (nSync.value === fonts.value.length) emit('sync');
    });
</script>

<style lang="scss" scoped></style>
